
// desctop menu acc
const servicesLink = document.getElementById('desctopAcc');
const servicesCont = document.getElementById('desctopAccCont');
const servicesMenu = document.getElementById('menu-acc');

servicesLink.addEventListener('click', function (e) {
    e.preventDefault();

    servicesCont.classList.toggle('active');
    servicesMenu.classList.toggle('show');

})


// desctop lang switch
const trigger = document.querySelector('.language_selector');
const list = document.querySelector('.languages');

trigger.addEventListener('click', function (e) {
    e.preventDefault();

    trigger.classList.toggle('active');
    list.classList.toggle('show');
 })

// tabs

const tabs = document.querySelectorAll('.tab__list-item');
const tabsContainer = document.querySelector('.numbers__tab-list');
const tabsContent = document.querySelectorAll('.tab__content-item');


if(tabsContainer !== null){
    tabsContainer.addEventListener('click', function (e) {
        e.preventDefault();
        const clicked = e.target.closest('.tab__list-item');

        // Guard clause
        if (!clicked) return;

        // Remove active classes
        tabs.forEach(t => t.classList.remove('tab__list-item-active'));
        tabsContent.forEach(c => c.classList.remove('tab__content-item-active'));

        // Activate tab
        clicked.classList.add('tab__list-item-active');

        // Activate content area
        document
            .querySelector(`.tab__content-item--${clicked.dataset.tab}`)
            .classList.add('tab__content-item-active');
    });
}



/// mob menu btn

const mobMenuBtn = document.querySelector('.container__mob-btn');
const mobMenu = document.querySelector('.container__mob-menu');
const mobMenuClose = document.querySelector('.mob__nav-close');
const mobLangMenuBtn = document.querySelector('.language_selector-mob');
const mobLangMenu = document.querySelector('.languages__mob');

mobMenuBtn.addEventListener('click', function (e) {
    e.preventDefault();
    mobMenu.style.display = 'block'
});

mobMenuClose.addEventListener('click', function (e) {
    e.preventDefault();
    mobMenu.style.display = 'none'
});

mobLangMenuBtn.addEventListener('click', function (e) {
    e.preventDefault();
    mobLangMenu.classList.toggle("hidden");
});

/// sticky nav (IntersectionObserver scroll)

const footer = document.querySelector(".footer");
const nav =  document.querySelector(".main__menu-cont");

const stickyNav = function (entries) {
    const [entry] = entries;

    if (entry.isIntersecting) nav.classList.add("non_fixed");
    else nav.classList.remove("non_fixed");
};

const footerObserver = new IntersectionObserver(stickyNav, {
    root: null,
    threshold: 0,
});

footerObserver.observe(footer);

// Lazy loading images
const imgTargets = document.querySelectorAll('img[data-src]');

const loadImg = function (entries, observer) {
    const [entry] = entries;

    if (!entry.isIntersecting) return;

    // Replace src with data-src
    entry.target.src = entry.target.dataset.src;

    entry.target.addEventListener('load', function () {
        entry.target.classList.remove('lazy-img');
    });

    observer.unobserve(entry.target);
};

const imgObserver = new IntersectionObserver(loadImg, {
    root: null,
    threshold: 0,
    rootMargin: '200px'
});

imgTargets.forEach(img => imgObserver.observe(img));

////// nav desctop menu active links

const url = window.location.href.substring(window.location.href.lastIndexOf("/"));
const hostLength = window.location.host.length + 9;
const blogLinkDecstop = document.getElementById('blog');
const blogLinkMob = document.getElementById('blogMob');

// console.log(blogLinkMob);

const navDesctop = document.querySelectorAll('.main__menu-list a');

navDesctop.forEach(element => {

        if(window.location.href.length == hostLength && element.href.length ==  hostLength) {
            element.parentElement.classList.add('active-link');
        }
        else if(window.location.href.length > hostLength && element.href.includes(url)) {
            element.parentElement.classList.add('active-link');
        }

        else if(window.location.href.includes('blog') || window.location.href.includes('article') || window.location.href.includes('category')) {
        blogLinkDecstop.classList.add('active-link');

        }

   })

const navMob = document.querySelectorAll('.mobmenu__main a');
navMob.forEach(element => {

    if(window.location.href.length == hostLength && element.href.length ==  hostLength) {
    element.parentElement.classList.add('active-link');
}
else if(window.location.href.length > hostLength && element.href.includes(url)) {
    element.parentElement.classList.add('active-link');
}

else if(window.location.href.includes('blog') || window.location.href.includes('article') || window.location.href.includes('category')) {
    blogLinkMob.classList.add('active-link');

}

})


/// ajax trunks


const trunkBtn = document.querySelector('#trunkBtn');

trunkBtn.addEventListener('click', function (e) {
    e.preventDefault();
    console.log('Hi!')
    // trunksRequest();
});

const trunksRequest = function () {
fetch('trunks/all_trunks')
.then((response) => {
return response.json();
})
.then((data) => {
console.log(data);
});
}

